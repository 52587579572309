import React from "react"
import Footer from "../components/Footer/Footer"
import Navbar from "../components/Navbar/Navbar"
import Header from "../components/Headers/Header"
import FirstSection from "../components/Development/FirstSection"
import SecondSection from "../components/Development/SecondSection"
import ThirdSection from "../components/Development/ThirdSection"

import Background from "../images/GeneralsectionbackgroundDevelopment.png"
import BackgroundMobile from "../images/Generalsectionbackground-mobile.png"

const developmentPage = () => {
  return (
    <div>
      <Navbar />
      <Header
        title="Deus Engine"
        background={Background}
        backgroundMobile={BackgroundMobile}
      />
      <article className="bg-IntermetaDarkPurple">        
        <section>
          <SecondSection />
        </section>
      </article>
      <Footer />
    </div>
  )
}

export default developmentPage
