import React from "react"
import useMediaQuery from "../../hooks/useMediaQuery"
import Background from "../../images/developmentBackgroundSecondSection.png"
import BackgroundMobile from "../../images/developmentBackgroundSecondSectionMobile.png"
import ContentV5 from "../ContentV/ContentV5/ContentV5"
import ContentV5r from "../ContentV/ContentV5/ContentV5r"

const SecondSection = () => {
  const isDesktop = useMediaQuery("(min-width: 1400px)")

  return (
    <div className="pt-[20rem] pb-16 2xl:pt-28 2xl:pb-0">
      {/* <div className="lg:h-[110vh] h-[150vh]  flex flex-col items-center">
        {isDesktop ? (
          <img
            className="relative lg:h-[110vh] top-0 -z-20 w-full scale-120 object-cover"
            src={Background}
          />
        ) : (
          <img
            className="relative h-[200vh] top-0 -z-20 w-full scale-120 object-cover"
            src={BackgroundMobile}
          />
        )} */}
      <div className="">
        <ContentV5r
          Title="acessible and reliable"
          Content="PAR 9: Lorem ipsum dolor sit amet, consectetue  adipiscing elit, sed diam nonummy nibh euismod rem ipsum dolor sit amet. 
                        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod rem ipsum dolor sit amet.
                        m dolor sit amet.ectetuer adipiscing elit, sed diam nonummy "
          IMGSrc="eee"
          ButtonText="Lorem ipsum"
        />
      </div>
      <div className="">
        <ContentV5
          Title="decentralized network"
          Content="PAR 9: Lorem ipsum dolor sit amet, consectetue  adipiscing elit, sed diam nonummy nibh euismod rem ipsum dolor sit amet. 
                        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod rem ipsum dolor sit amet.
                        m dolor sit amet.ectetuer adipiscing elit, sed diam nonummy "
          IMGSrc="eee"
          ButtonText="Lorem ipsum"
        />
      </div>
      <div className="pb-32">
        <ContentV5r
          Title="intermetability"
          Content="PAR 9: Lorem ipsum dolor sit amet, consectetue  adipiscing elit, sed diam nonummy nibh euismod rem ipsum dolor sit amet. 
                        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod rem ipsum dolor sit amet.
                        m dolor sit amet.ectetuer adipiscing elit, sed diam nonummy "
          IMGSrc="eee"
          ButtonText="Lorem ipsum"
        />
      </div>
    </div>
  )
}

export default SecondSection
