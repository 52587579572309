import React from "react"

interface V5rProps {
  Title: string
  Content: string
  IMGSrc: string
  ButtonText: string
}

const V5r = ({ Title, Content, IMGSrc, ButtonText }: V5rProps) => {
  return (
    <div className="mt-28 md:mt-0 flex justify-center h-auto">
      <div className="flex lg:flex-row-reverse flex-col justify-start w-[90%] lg:w-[90%]">
        <div className="flex lg:flex-row-reverse flex-row lg:justify-center justify-start items-center lg:w-[55%] w-[95%]">
          <div className="lg:w-[30%] w-[25%] lg:pt-0 pt-0 flex flex-col justify-center items-center">
            <div className="lg:rotate-[90deg] lg:mb-16 rotate-[-90deg] h-20 w-[120%] lg:pt-10 flex flex-row justify-center lg:mt-44 lg:text-3xl text-xl tracking-[0.4em] font-extrabold text-white opacity-50 ">
              {Title}
            </div>
          </div>
          <div className="lg:w-[60%] w-[70%] lg:h-[40vh] bg-gradient-to-r from-cyan-500 to-blue-500 aspect-[5/4] rounded-tr-[3rem] rounded-bl-[3rem] lg:mr-0">
            {IMGSrc}
          </div>
        </div>
        <div className="lg:w-[40%] w-[90%] flex flex-row lg:justify-center lg:ml-0 pl-8 justify-end items-center aspect-[5/4] text-white">
          <div className="lg:w-[50%] w-[80%] aspect-[5/4] flex flex-col justify-start">
            <div className="lg:mt-2 text-bold lg:text-base text-sm !leading-relaxed opacity-70">
              {Content}
            </div>
            <button className="border-b-2 mt-2 py-2 w-fit flex items-center justify-center lg:text-lg text-md text-blue-400 border-blue-400 font-semibold tracking-wide">
              {ButtonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default V5r
